*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}
 /* for desktop */
 .whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 50px;
      height: 50px;
      bottom: 20px;
      right: 10px;
      font-size: 30px;
  }
}
